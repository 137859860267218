<div data-ng-include="'blocks/conference_logo.html'"></div>

<div class="wrapper-lg">
    <div class="padder-lg">

        <div data-ng-include="'blocks/coi_review_conference_nav.html'"></div>

        <div ng-show="!review_faculty.length" class="alert alert-info">
            There are currently no faculty requiring review.
        </div>

        <div ng-show="review_faculty.length">
            <uib-tabset class="tab-container" justified="false" active="true">
                <uib-tab index="'faculty_needing_review'" select="active_tab = 'faculty_needing_review'">
                    <uib-tab-heading>
                        Needs Review
                    </uib-tab-heading>
                    <div>
                        <div class="panel panel-default m-n table-responsive">
                            <table class="table table-hover table-condensed">
                                <tr>
                                    <th>Faculty Name</th>
                                    <th>Meeting Role</th>
                                    <th>Review Status</th>
                                    <th>Restriction Status</th>
                                    <th></th>
                                </tr>
                                <tr ng-repeat="faculty in review_faculty" ng-if="faculty.review_status == 'Needs Review'">
                                    <td><a ng-click="loadCOIReviewFacultyModal(faculty)">{{faculty.full_name}}</a></td>
                                    <td>{{faculty.meeting_role}}</td>
                                    <td>{{faculty.review_status}}</td>
                                    <td>{{faculty.restriction_status}}</td>
                                    <td nowrap="">
                                        <button type="button" class="btn btn-default btn-xs" ng-click="loadCOIReviewFacultyModal(faculty)">Review</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </uib-tab>
                <uib-tab index="'faculty_complete'" select="active_tab = 'faculty_complete'">
                    <uib-tab-heading>
                        Mitigated
                    </uib-tab-heading>
                    <div>
                        <div class="panel panel-default m-n table-responsive">
                            <table class="table table-hover table-condensed">
                                <tr>
                                    <th>Faculty Name</th>
                                    <th>Meeting Role</th>
                                    <th>Review Status</th>
                                    <th>Restriction Status</th>
                                    <th>Completed At</th>
                                    <th></th>
                                </tr>
                                <tr ng-repeat="faculty in review_faculty"
                                    ng-if="faculty.review_status == 'Mitigated'">
                                    <td><a ng-click="loadCOIReviewFacultyModal(faculty)">{{faculty.full_name}}</a></td>
                                    <td>{{faculty.meeting_role}}</td>
                                    <td>{{faculty.review_status}}</td>
                                    <td>{{faculty.restriction_status}}</td>
                                    <td>{{formatMomentDate(faculty.completed_at,'MM/DD/YYYY h:mm A')}}</td>
                                    <td nowrap="">
                                        <button type="button" class="btn btn-default btn-xs" ng-click="loadCOIReviewFacultyModal(faculty)">View</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </uib-tab>
                <uib-tab index="'faculty_nothing_to_disclose'" select="active_tab = 'faculty_nothing_to_disclose'">
                    <uib-tab-heading>
                        Nothing To Disclose
                    </uib-tab-heading>
                    <div>
                        <div class="panel panel-default m-n table-responsive">
                            <table class="table table-hover table-condensed">
                                <tr>
                                    <th>Faculty Name</th>
                                    <th>Meeting Role</th>
                                    <th>Review Status</th>
                                    <th>Restriction Status</th>
                                    <th>Completed At</th>
                                    <th></th>
                                </tr>
                                <tr ng-repeat="faculty in review_faculty"
                                    ng-if="faculty.review_status == 'Nothing To Disclose'">
                                    <td><a ng-click="loadCOIReviewFacultyModal(faculty)">{{faculty.full_name}}</a></td>
                                    <td>{{faculty.meeting_role}}</td>
                                    <td>{{faculty.review_status}}</td>
                                    <td>{{faculty.restriction_status}}</td>
                                    <td>{{formatMomentDate(faculty.completed_at,'MM/DD/YYYY h:mm A')}}</td>
                                    <td nowrap="">
                                        <button type="button" class="btn btn-default btn-xs" ng-click="loadCOIReviewFacultyModal(faculty)">View</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </uib-tab>
                <!--
                <uib-tab index="'faculty_incoplete'" select="active_tab = 'faculty_incoplete'">
                    <uib-tab-heading>
                        Incomplete
                    </uib-tab-heading>
                    <div>
                        <div class="panel panel-default m-n table-responsive">
                            <table class="table table-hover table-condensed">
                                <tr>
                                    <th>Faculty Name</th>
                                    <th>Meeting Role</th>
                                    <th>Review Status</th>
                                    <th>Completed At</th>
                                    <th></th>
                                </tr>
                                <tr ng-repeat="faculty in review_faculty"
                                    ng-if="faculty.review_status == 'Incomplete'">
                                    <td><a ng-click="loadCOIReviewFacultyModal(faculty.id)">{{faculty.full_name}}</a></td>
                                    <td>{{faculty.meeting_role}}</td>
                                    <td>{{faculty.review_status}}</td>
                                    <td>--</td>
                                    <td nowrap="">
                                        <button type="button" class="btn btn-default btn-xs" ng-click="loadCOIReviewFacultyModal(faculty.id)">View</button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </uib-tab>
                -->
            </uib-tabset>
        </div>

    </div>
</div>